import React from "react"
import { Route, Routes } from "react-router-dom"
import "./App.css"
import Main from "./Main"


const NodeCache = require("node-cache")
export const myCache = new NodeCache()
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
    </Routes>
  )
}

export default App