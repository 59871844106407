import { DataStore } from "aws-amplify"
import { useState, useEffect, FormEvent, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { myCache } from "./App"
import { GetCurrentUser } from "./functional/getCurrentUser"
import "./Main.css"
import { UserState } from "./models"
import UserMenu from "./widgets/UserMenu"

const Main = () => {

  interface Tweet {
    id: string
    hashtags: string[]
    keywords: string[]
    account: string
    summary: string
  }
  interface SeriesAccounts {
    series: string
    accounts: string[]
  }
  type DatesToTweets = [string, [string, Tweet[]]]

  const [selectedMonth, setSelectedMonth] = useState(0)
  const [selectedDay, setSelectedDay] = useState(0)
  const [state, setState] = useState({
    seriesMap: new Map(),
    seriesInput: new Set<string>(),
    accountInput: '',
    loading: false,
    results: new Map(),
    selectedMonth: 0,
    hideCheckedItems: false,
    seenItems: new Set<string>()
  })
  const [userStateObj, setUserStateObj] = useState<UserState>();
  const [fetchingUserInfo, setFetchingUserInfo] = useState(false)
  const { currentUser } = GetCurrentUser();

  const fetchUserState = () => {
    if (!currentUser || fetchingUserInfo)
      return
    setFetchingUserInfo(true)
    DataStore.query(UserState, pred => pred.accountId.eq(currentUser.getUsername())).then((result) => {
      setUserStateObj(result[0])
      setFetchingUserInfo(false)
    })
  }
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]

  const navigate = useNavigate()
  const location = useLocation()

  const getAllSeries = () => {
    const url = `https://v8aod0a0a1.execute-api.us-east-1.amazonaws.com/Prod/series`
    return fetch(url).then((res) => res.json())
  }

  const fetchData = (acc: string) => {
    const url = `https://v8aod0a0a1.execute-api.us-east-1.amazonaws.com/Prod/info?account=${acc.toLowerCase()}`
    return fetch(url)
      .then((res) => res.json())
      .catch(() => console.log("Error"))
  }

  const firstTimeLoad = async () => {
    return await getAllSeries().then((data) => {
      var tempMap = new Map()
      data.forEach((seriesAccounts: SeriesAccounts) =>
        tempMap.set(seriesAccounts.series, seriesAccounts.accounts)
      )
      state.seriesMap = tempMap

      refreshList()
      const today = new Date();
      setSelectedMonth(today.getMonth() + 1);
      // setSelectedDay(today.getDate());
    })
  }

  const previousLocation = useRef("")

  useEffect(() => {
    firstTimeLoad();
  }, []);

  const syncStateFromDatastore = async () => {
    if (userStateObj) {
      state.seriesInput = new Set(userStateObj.series)
      state.seenItems = new Set(userStateObj.seenItems)
      state.hideCheckedItems = userStateObj.hideCheckedItems
    }
  }

  const syncStateToDatastore = () => {
    if (currentUser && !state.loading) {
      if (userStateObj) {
        let result = UserState.copyOf(userStateObj, updated => {
          updated.seenItems = Array.from(state.seenItems)
          updated.series = Array.from(state.seriesInput)
          updated.hideCheckedItems = state.hideCheckedItems
        })
        DataStore.save(result)
      }
      else
        DataStore.save(new UserState({
          accountId: currentUser.getUsername(),
          seenItems: Array.from(state.seenItems),
          series: Array.from(state.seriesInput),
          hideCheckedItems: state.hideCheckedItems
        })).then((result) => fetchUserState())
    }
  }

  useEffect(() => {
    (async () => {
      await syncStateFromDatastore()
    })()
  }, [userStateObj])

  useEffect(() => {
    syncStateToDatastore()
  }, [state.seenItems, state.hideCheckedItems])

  useEffect(() => {
    fetchUserState()
  }, [currentUser])

  const manageCache = () => {
    if (state.loading)
      return
    if (previousLocation.current !== location.key) {
      const localState = myCache.take(location.key)
      if (localState) {
        setState({
          ...localState,
          seriesMap: new Map(Object.entries(localState.seriesMap)),
          seriesInput: localState.seriesInput,
          results: new Map(Object.entries(localState.results))
        })
      }
      else if (state.results.size != 0) {
        myCache.set(location.key, {
          ...state,
          seriesMap: Object.fromEntries(state.seriesMap),
          seriesInput: state.seriesInput,
          results: Object.fromEntries(state.results)
        }, 3600)
        previousLocation.current = location.key
      }
    }
  }

  useEffect(() => {
    manageCache()
  }, [location, state.results])

  const updateURL = () => {
    const newUrl = window.location.pathname/*  + '?' + queryParams.toString(); */
    navigate(newUrl, { state: state })
  }

  //   useEffect(() => {
  //     if (state.seriesInput.size != 0)
  //       updateURL()
  //   }, [selectedMonth])

  const generateResults = (accountList: string[]) => {
    var newResults = new Map()
    Promise.all(
      accountList.map(async (acc) => {
        await fetchData(acc).then(
          data => {
            newResults.set(acc, data)
          })
      })
    ).then(() => {
      state.results = newResults
      setState({ ...state, loading: false })
    })
  }

  const refreshList = () => {
    setState({ ...state, loading: true })
    var accountList = state.accountInput.split(",").filter((x) => x !== "")
    state.seriesInput.forEach((series) => {
      accountList = accountList.concat(state.seriesMap.get(series)!!)
    })
    generateResults(accountList)
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault()
    syncStateToDatastore()
    refreshList()
    updateURL() // push new browser history when submitting, so users can go back
  }

  const displayDates = () => {
    // Filter out months that are not selected
    const filteredMonths = months.filter(month => selectedMonth === 0 || month === selectedMonth)
    const filteredDays = days.filter(day => selectedDay === 0 || day === selectedDay)

    return filteredMonths.map((month) => {
      return (
        <div key={month}>
          {displayDaysFor(month, filteredDays)}
        </div>
      )
    })
  }

  const displayDaysFor = (month: number, filteredDays: number[]): JSX.Element[] => {
    return filteredDays.map((day: number) => {
      const seriesToTweets: Map<string, Tweet[]> = getTweetsForAccountsInSeries(month, day)

      return (
        <div key={day}>
          {seriesToTweets.size != 0 ? <h3>{month}/{day}</h3> : <p>{month}/{day}</p>}
          {displayTweets(seriesToTweets)}
        </div>
      )
    })
  }


  const getTweetsForAccountsInSeries = (month: number, day: number): Map<string, Tweet[]> => {
    const seriesToTweets: Map<string, Tweet[]> = new Map()
    // Iterate through each series and get tweets for all accounts in the current series
    state.seriesMap.forEach((accounts: string[], series: string) => {
      const tweets: Tweet[] = []
      accounts.forEach((account: string) => {
        const data: DatesToTweets | undefined = state.results.get(account)
        if (
          data &&
          data[month] &&
          data[month][day]
        ) {
          (data[month][day] as Tweet[]).forEach((tweet: Tweet) => {
            if (!(state.seenItems.has(tweet.id) && state.hideCheckedItems))
              tweets.push(tweet)
          })
        }
      })
      if (tweets.length != 0)
        seriesToTweets.set(series, tweets)
    })

    return seriesToTweets
  }

  const handleSeenChange = (tweet_id: string) => {
    let updatedSeenItems = new Set(state.seenItems);

    if (updatedSeenItems.has(tweet_id)) {
      updatedSeenItems.delete(tweet_id);
    } else {
      updatedSeenItems.add(tweet_id);
    }

    setState({ ...state, seenItems: updatedSeenItems });
  }

  const displayTweets = (seriesToTweets: Map<string, Tweet[]>): JSX.Element[] => {
    return Array.from(seriesToTweets.entries()).map(([series, tweets]) => {
      return <div key={series} className="seriesDiv">
        <h3 className="seriesHeader">{series}</h3>
        {tweets.map((tweet: Tweet, _: number) => {
          let isSeen = state.seenItems.has(tweet.id)
          if (isSeen && state.hideCheckedItems)
            return <></>
          return (
            <div key={tweet.id} className={`tweetDiv ${isSeen ? 'seenItem' : ''}`}>
              <input
                type="checkbox"
                className="markAsSeen"
                checked={isSeen}
                onChange={() => handleSeenChange(tweet.id)}
                style={{ marginRight: '10px' }}
              />
              <div>
                <p>
                  {tweet.summary}
                  <a href={`https://twitter.com/${tweet.account}/status/${tweet.id}`} target="_blank">Link</a>
                </p>
                <p>
                  ・{tweet.keywords.sort().map((keyword: string) => (<i>{keyword}・</i>))}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    })
  }


  return (
    <div>
      <UserMenu />
      <form method="get" onSubmit={handleSubmit}>

        <label key="selectAll">
          <input
            name="account"
            type="checkbox"
            disabled={state.loading}
            checked={state.seriesInput.size === state.seriesMap.size}
            onChange={(e) => {
              let newSeriesInput = e.target.checked ? new Set(state.seriesMap.keys()) : new Set()
              setState({ ...state, seriesInput: newSeriesInput })
            }}
          />
          Select All
          <br />
        </label>
        {Array.from(state.seriesMap.keys()).map((series) => (
          <label key={series}>
            <input
              name="account"
              type="checkbox"
              checked={state.seriesInput.has(series)}
              disabled={state.loading}
              onChange={(e) => {
                let newSeriesInput = new Set(state.seriesInput)
                if (e.target.checked)
                  newSeriesInput.add(series)
                else
                  newSeriesInput.delete(series)
                setState({ ...state, seriesInput: newSeriesInput })
              }}
            />
            {series}
            <br />
          </label>
        ))}
        <label key="manual">
          accounts:&nbsp;
          <input
            name="account"
            onChange={(e) => {
              setState({ ...state, accountInput: e.target.value })
            }}
          />
          <br />
        </label>
        <button type="submit">Submit</button>
      </form>
      <hr />
      <label>
        Month:
        <select
          value={selectedMonth}
          onChange={(e) =>
            setSelectedMonth(Number.parseInt(e.target.value))
          }
        >
          <option key="All" value="0">
            All
          </option>
          {months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      </label>
      <label>
        Day:
        <select
          value={selectedDay}
          onChange={(e) =>
            setSelectedDay(Number.parseInt(e.target.value))
          }
        >
          <option key="All" value="0">
            All
          </option>
          {days.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </label>
      <label>
        Hide checked items
        <input
          type="checkbox"
          checked={state.hideCheckedItems}
          onChange={(e) => setState({ ...state, hideCheckedItems: e.target.checked })}
        />
      </label>
      <div>{state.loading ? "Loading..." : displayDates()}</div>
    </div>
  )
}
export default Main