
import React, { useState } from 'react';
import Modal from './Modal';
import '../css/common.css';
import { GetCurrentUser } from '../functional/getCurrentUser';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth, DataStore } from 'aws-amplify';

const UserMenu = () => {
    const [opened, setOpened] = useState(false);
    const { currentUser } = GetCurrentUser();

    const ItsuDocoAuth = () => {
        const components = {
        }

        const services = {
            async handleSignUp(formData: { username: any; password: any; attributes: any; }) {
                let { username, password, attributes } = formData;
                // custom username
                return Auth.signUp({
                    username,
                    password,
                    attributes,
                    autoSignIn: {
                        enabled: true,
                    },
                });
            },
        };

        return (
            <Modal isOpen={opened} onClose={() => setOpened(false)}>
                <Authenticator signUpAttributes={[]} initialState="signUp" socialProviders={['google']} components={components} services={services}>
                </Authenticator>
            </Modal>
        )
    }

    const GuestMenu = () => (
        <p>
            <a href="#register" onClick={() => setOpened(true)}>Sign Up</a>
            <a href="#login" onClick={() => setOpened(true)}>Log In</a>
        </p>
    )

    const LoggedInMenu = () => (
        <div>
            <p>Welcome {currentUser?.getUsername()}</p>
            <p><a href="#logout" onClick={() => Auth.signOut()}>Log Out</a></p>
            <p><a href="#clear" onClick={() => DataStore.clear()}>Clear</a></p>
        </div>
    )

    return (
        <div>
            {currentUser ? <LoggedInMenu /> : <GuestMenu />}
            {opened ? <ItsuDocoAuth /> : <></>}
        </div>
    )
}

export default UserMenu