import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export const GetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
      } catch (error) {
        // Not authenticated, handle as necessary
        setCurrentUser(null);
      }
    };

    getCurrentUser();

    const updateUser = (data: any) => {
      setCurrentUser(data);
    };

    // Auth event listener
    Hub.listen('signIn', updateUser);

    return () => {
      Hub.remove('signIn', updateUser);
    };
  }, []);

  return { currentUser };
};