/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://h6c7vrlm7zaibk6sc5aczjvr3q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-lww6zt3uivbnrh5az2vj7nqlhu",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Xm4Rk9KtN",
    "aws_user_pools_web_client_id": "4smhflbsugluall8135dhce2a",
    "oauth": {
        "domain": "itsudoco.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "phone"
        ],
        "redirectSignIn": "http://localhost:3000,https://itsu.greenlittleapple.com",
        "redirectSignOut": "http://localhost:3000,https://itsu.greenlittleapple.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
